<template>
  <v-card flat>
    <v-list two-line>
      <v-subheader>{{$t('team.users')}}</v-subheader>
      <v-list-item link v-for="team_user in users.concat(invites)" :key="team_user.email">
        <v-list-item-icon style="padding-top: 10px;">
          <v-chip
            v-if="team_user.team_orderer"
            style="width: 70px; text-align: center; justify-content: center;"
            small
            color="primary darken-2"
          >
            {{$t('team.owner')}}
          </v-chip>
          <v-chip
            v-else-if="team_user.team_admin"
            style="width: 60px; text-align: center; justify-content: center;"
            small
            color="primary"
          >
            {{$t('team.admin')}}
          </v-chip>
          <v-chip
            v-else-if="team_user.joined"
            style="width: 60px; text-align: center; justify-content: center;"
            small
          >
            {{$t('team.user')}}
          </v-chip>
          <v-chip
            color="secondary"
            v-else
            style="width: 60px; text-align: center; justify-content: center;"
            small
          >
            {{$t('team.invited')}}
          </v-chip>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{team_user.full_name}}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{team_user.email}}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon v-if="team_user.email != user.email || !team_user.joined">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small 
                text 
                class="px-0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="team_user.joined" link @click="setAdmin(team_user)">
                <v-list-item-icon>
                  <v-icon :color="team_user.team_admin ? 'secondary' : 'primary'">
                    mdi-account-check-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ team_user.team_admin ? $t('team.make_user') : $t('team.make_admin')}}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="startDeleting(team_user)">
                <v-list-item-icon>
                  <v-icon color="red">
                    mdi-account-arrow-left-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title color="red">
                  <span v-if="team_user.joined">{{$t('team.delete_user')}}</span>
                  <span v-else>{{$t('team.remove_invite')}}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-icon>
      </v-list-item>
      <v-card-text>
        {{$t('team.users')}} {{ users.length + invites.length }} / {{ currentTeam ? currentTeam.users : 0 }}
      </v-card-text>
    </v-list>
    <ConfirmationDialog
      :show="showDialog"
      :text.sync="dialog.dialogText"
      :btnText.sync="dialog.btnText"
      :btnColor.sync="dialog.btnColor"
      :subText.sync="dialog.subText"
      @accept="accept()"
      @decline="decline()"
    ></ConfirmationDialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmationDialog from '@/components/ConfirmationDialog'
export default {
  components: { ConfirmationDialog },
  created() {
    this.initTeamUsers(this.currentTeam.id)
    this.initTeamInvites(this.currentTeam.id)
  },
  data: () => ({
    showDialog: false,
    dialog: {
      showDialog: false,
      dialogText: '',
      btnText: '',
      btnColor: '',
    },
    currentAction: '',
    deletingUser: null
  }),
  methods: {
    ...mapActions('team', [
      'initTeamUsers',
      'initTeamInvites',
      'deleteUser',
      'setAdminStatus',
      'deleteInvite'
    ]),
    ...mapActions('noti', [
      'showSnackbar',
      'error',
      'info'
    ]),
    startDeleting(user) {
      if(!user.joined) {
        this.deleteInvite({email: user.email, team_id: this.currentTeam.id})
          .then(() => {
            this.info('ok!')
          })
          .catch(e => {
            this.error(e)
          })
        return
      }

      this.currentAction = 'deleting'
      this.deletingUser = user
      this.showDialog = true
      this.dialog = {
        dialogText: this.$t('team.remove_user_from_team') + '?',
        btnText: this.$t('remove'),
        btnColor: 'red',
        subText: this.$t('team.are_you_sure', [user.full_name, this.currentTeam.team_name])
      }
    },
    delete(email) {
      this.deleteUser({email, team_id: this.currentTeam.id})
        .then(() => {
          this.showSnackbar({
            text: this.$t('team.user_removed', [email])
          })
        })
        .catch(err => {
          this.showSnackbar({
            text: err.response.data,
            type: 'error'
          })
        })
    },
    setAdmin(user) {
      this.setAdminStatus({
        team_id: this.currentTeam.id, 
        email: user.email,
        team_admin: !user.team_admin
      })
        .then(() => {
          this.showSnackbar({
            text: this.$t('team.admin_status_changed', [user.full_name]),
            duration: 3
          })
        })
        .catch((e) => {
          this.error(e)
        })
    },
    accept() {
      switch(this.currentAction) {
        case 'deleting':
          this.delete(this.deletingUser.email)
          this.decline()
      }
    },
    decline() {
      switch(this.currentAction) {
        case 'deleting':
          this.deletingUser = null
      }
      this.showDialog = false
      this.dialog = {
        showDialog: false,
        dialogText: '',
        btnText: '',
        btnColor: '',
      },
      this.currentAction = null
    }
  },
  computed: {
    ...mapGetters('user', [
      'currentTeam',
      'user'
    ]),
    ...mapGetters('team', [
      'users',
      'invites'
    ])
  }
}
</script>
